import Vue from 'vue'
import App from './App.vue'

// import EasySlider from 'vue-easy-slider'
// Vue.use(EasySlider)
Vue.config.productionTip = false


import 'amfe-flexible'

new Vue({
  render: h => h(App),
}).$mount('#app')
